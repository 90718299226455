<template>
  <div class="question-block" @click="isShowBlock = !isShowBlock">
    <h3 class="question-block__title">{{ title }}</h3>

    <div class="question-block__body">
      <transition name="slide">
        <slot v-if="isShowBlock" />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionBlock",
  props: {
    title: {
      type: String,
      required: true,
      default: "Block title"
    }
  },
  data() {
    return {
      isShowBlock: false
    };
  }
};
</script>

<style lang="scss">
.question-block {
  cursor: pointer;
  background-color: var(--question-block-bg);
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 15px;
  &__title {
    font-size: 23px;
  }

  &__body {
    font-size: 19px;

    img {
      display: block;
      margin-top: 1.5rem;
      background-color: white;
      //width: 100%;
      /* height: auto; */
      height: auto;
      max-height: 600px;
      width: auto;
    }
  }
}

.slide-enter-active {
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
