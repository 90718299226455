<template>
  <TepmplateBlock
    mod-class
    content-class="mt-4 d-block"
    title-page="Инструкции"
  >
    <div class="scroll-container">
      <vuescroll>
        <question-block title="Как создать задачу">
          <div>
            <p>
              Задачу можно создать с привязкой к аппарату или без. Это можно
              сделать через веб интерфейс.
            </p>
            <p class="font-italic font-weight-bold">
              Сначала создадим задачу с привязкой к аппарату.
            </p>

            <p>
              1. В главном меню нажимаем на пункт «Техника»
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img001"
                alt="Скриншот"
              />
              <img v-else :src="images.img001Dark" alt="Скриншот" />
            </p>
            <p>
              2. Нажимаем на аппарат
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img003"
                alt="Скриншот"
              />
              <img v-else :src="images.img003Dark" alt="Скриншот" />
            </p>
            <p>
              3. И попав внутрь аппарата нажимаем на кнопку «+» в правом нижнем
              углу
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img005"
                alt="Скриншот"
                style="width: 80%"
              />
              <img
                v-else
                :src="images.img005Dark"
                alt="Скриншот"
                style="width: 80%"
              />
            </p>
            <p>
              4. Выбираем нужный тип задачи и она будет создана
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img007"
                alt="Скриншот"
                style="width: 80%"
              />
              <img
                v-else
                :src="images.img007Dark"
                alt="Скриншот"
                style="width: 80%"
              />
            </p>
            <p class="font-italic font-weight-bold">
              Теперь создадим задачу без привязки к аппарату, например когда
              мастер едет обслужить несколько единиц техники.
            </p>
            <p>
              В главном меню заходим в пункт «Задачи»
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img009"
                alt="Скриншот"
              />
              <img v-else :src="images.img009Dark" alt="Скриншот" />
            </p>
            <p>
              Далее нажимаем кнопку «+» в правом нижнем углу
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img011"
                alt="Скриншот"
                style="width: 100%"
              />
              <img
                v-else
                :src="images.img011Dark"
                alt="Скриншот"
                style="width: 100%"
              />
            </p>

            <p>
              Выбираем нужный тип и задача будет создана.
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img013"
                alt="Скриншот"
                style="width: 80%"
              />
              <img
                v-else
                :src="images.img013Dark"
                alt="Скриншот"
                style="width: 80%"
              />
            </p>
          </div>
        </question-block>

        <question-block title="Как добавить новое устройство">
          <div>
            <p>
              1. В главном меню переходим в пункт «Техника»
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img001"
                alt="Скриншот"
              />
              <img v-else :src="images.img001Dark" alt="Скриншот" />
            </p>
            <p>
              2. Нажимаем на значок «+» в правом нижнем углу экрана.
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img017"
                alt="Скриншот"
                style="width: 80%"
              />
              <img
                v-else
                :src="images.img017Dark"
                alt="Скриншот"
                style="width: 80%"
              />
            </p>
            <p>
              3. Последовательно заполняем все обязательные поля, отмеченные
              красной рамкой и нажимаем кнопку «Добавить устройство».
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img019"
                alt="Скриншот"
              />
              <img v-else :src="images.img019Dark" alt="Скриншот" />
            </p>
          </div>
        </question-block>

        <question-block title="Как добавить новый офис">
          <div>
            <p>
              1. В главном меню переходим в пункт «Профиль»
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img021"
                alt="Скриншот"
              />
              <img v-else :src="images.img021Dark" alt="Скриншот" />
              >
            </p>
            <p>
              2. Переходим в пункт «Управление компанией»
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img023"
                alt="Скриншот"
              />
              <img v-else :src="images.img023Dark" alt="Скриншот" />
            </p>
            <p>
              3. Нажимаем на кнопку «+» рядом с пунктом «Добавить новый офис»
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img025"
                alt="Скриншот"
              />
              <img v-else :src="images.img025Dark" alt="Скриншот" />
            </p>
            <p>
              4. Далее заполняем минимум - обязательные поля, выделенные красной
              рамкой и нажимаем «Сохранить»
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img027"
                alt="Скриншот"
              />
              <img v-else :src="images.img027Dark" alt="Скриншот" />
            </p>
          </div>
        </question-block>

        <question-block title="Как добавить нового пользователя">
          <div>
            <p>
              1. В главном меню переходим в пункт «Профиль»
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img021"
                alt="Скриншот"
              />
              <img v-else :src="images.img021Dark" alt="Скриншот" />
            </p>
            <p>
              2. Переходим в пункт «Управление компанией»
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img023"
                alt="Скриншот"
              />
              <img v-else :src="images.img023Dark" alt="Скриншот" />
            </p>
            <p>
              3. Нажимаем на кнопку «+» рядом с пунктом «Создать пользователя в
              рамках своей компании»
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img029"
                alt="Скриншот"
              />
              <img v-else :src="images.img029Dark" alt="Скриншот" />
            </p>
            <p>
              4. Заполняем все поля, выбираем права доступа к чему будет у
              пользователя и нажимаем кнопку «Сохранить»
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img031"
                alt="Скриншот"
              />
              <img v-else :src="images.img031Dark" alt="Скриншот" />
            </p>
          </div>
        </question-block>

        <question-block title="Как включить/выключить подсказки">
          <div>
            <p>
              Чтобы включить/выключить знаки вопроса, которые содержат в себе
              пояснения к пунктам, нужно нажать на кнопку «Вопрос».
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img033"
                alt="Скриншот"
              />
              <img v-else :src="images.img033Dark" alt="Скриншот" />
            </p>
          </div>
        </question-block>

        <question-block title="Как посмотреть историю заявок">
          <div>
            <p>
              1. В главном меню заходим в пункт «Задачи»
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img009"
                alt="Скриншот"
              />
              <img v-else :src="images.img009Dark" alt="Скриншот" />
            </p>
            <p>
              2. В поле «Серийный номер» вводим серийный номер аппарата и
              нажимаем «Найти», после этого ниже мы увидим историю заявок по
              аппарату.
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img035"
                alt="Скриншот"
              />
              <img v-else :src="images.img035Dark" alt="Скриншот" />
            </p>
          </div>
        </question-block>

        <question-block title="Чат с персональным менеджером">
          <div>
            <p>
              1. В главном меню нажимаем на кнопку «Персональный менеджер»
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img037"
                alt="Скриншот"
              />
              <img v-else :src="images.img037Dark" alt="Скриншот" />
            </p>
            <p>
              2. Далее мы попадаем в чат и можем там написать сообщение.
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img039"
                alt="Скриншот"
              />
              <img v-else :src="images.img039Dark" alt="Скриншот" />
            </p>
          </div>
        </question-block>

        <question-block title="Как добавить/удалить тег для аппарата">
          <div>
            <p>
              1. Нажимаем на кнопку «+»
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img041"
                alt="Скриншот"
              />
              <img v-else :src="images.img041Dark" alt="Скриншот" />
            </p>
            <p>
              2. Далее в появившееся поле ввода вводим нужное значение тега и
              нажимаем кнопку «enter»
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img043"
                alt="Скриншот"
              />
              <img v-else :src="images.img043Dark" alt="Скриншот" />
            </p>
          </div>
        </question-block>

        <question-block title="Как найти нужный аппарат">
          <div>
            <p>
              1. В главном меню нажимаем на пункт «Техника»
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img001"
                alt="Скриншот"
              />
              <img v-else :src="images.img001Dark" alt="Скриншот" />
            </p>
            <p>
              2. Есть возможность ввести часть названия модели и серийном номере
              аппарата, также выбрать офис, к которому прикреплен аппарат и
              выбрать тип устройства.
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img047"
                alt="Скриншот"
                style="width: 100%"
              />
              <img
                v-else
                :src="images.img047Dark"
                alt="Скриншот"
                style="width: 100%"
              />
            </p>
            <p>
              3. Вводим «10», выбираем офис «СК-Ленинградка» и видим 11
              аппаратов.
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img049"
                alt="Скриншот"
              />
              <img v-else :src="images.img049Dark" alt="Скриншот" />
            </p>
          </div>
        </question-block>

        <question-block title="Как найти нужную задачу">
          <div>
            <p>
              1. В главном меню заходим в пункт «Задачи»
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img009"
                alt="Скриншот"
              />
              <img v-else :src="images.img009Dark" alt="Скриншот" />
            </p>
            <p>
              2. Фильтр состоит из возможности выбрать: статус задачи; ввести
              серийный номер аппарата, к которому привязана задачи; ввести
              стикер(номер на наклейке) номер аппарата; офис, который создал
              задачу; дата создания задачи «от и до»; переход в задачу по
              идентификатору, при этом нужно ввести номер и нажать на клавиатуре
              кнопку «enter». После ввода данных нужно нажать кнопку «Найти»,
              либо можно сбросить все введенные фильтры, нажав кнопку
              «Сбросить».
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img051"
                alt="Скриншот"
                style="width: 100%"
              />
              <img
                v-else
                :src="images.img051Dark"
                alt="Скриншот"
                style="width: 100%"
              />
            </p>
          </div>
        </question-block>

        <question-block
          title="Как работают оповещения в приложении + оповещения на почту"
        >
          <div>
            <p>
              1. После регистрации на сайте на электронную почту вы получите
              оповещение – электронное письмо, в нем будут основные данные для
              входа на сайт и ссылки.
            </p>
            <p>
              2. При создании новых заявок или их обновлении, на электронную
              почту будут приходить письма с изменениями, чтобы вы могли
              оперативно узнать об этих изменениях.
            </p>
            <p>
              3. Также находясь в приложении вы увидите оперативные изменения
              через раздел «Колокольчик». Желтая точка означает, что там есть
              новые события.
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img053"
                alt="Скриншот"
              />
              <img v-else :src="images.img053Dark" alt="Скриншот" />
            </p>
          </div>
        </question-block>

        <question-block title="Как прикрепить пользователя к офису(офисам)">
          <div>
            <p>
              1. В главном меню переходим в пункт «Профиль»
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img021"
                alt="Скриншот"
              />
              <img v-else :src="images.img021Dark" alt="Скриншот" />
            </p>
            <p>
              2. Переходим в пункт «Управление компанией»
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img023"
                alt="Скриншот"
              />
              <img v-else :src="images.img023Dark" alt="Скриншот" />
            </p>
            <p>
              3. Нажимаем на кнопку «+» рядом с пунктом «Назначить права
              пользователям»
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img055"
                alt="Скриншот"
              />
              <img v-else :src="images.img055Dark" alt="Скриншот" />
            </p>
          </div>
        </question-block>

        <!--      Что делать, если приложение не работает 1. Нужно сложить руки в замок и-->
        <!--      прижать ими голову к коленям… в таком положении ожидаем, пока работа-->
        <!--      приложения будет возобновлена.-->

        <question-block title="Как получить наклейку на аппарат">
          <div>
            <p>
              1. Если аппарат уже заведен в систему, то ему присвоен стикер
              номер.
            </p>

            <p>
              2. В главном меню нажимаем на пункт «Техника»
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img001"
                alt="Скриншот"
              />
              <img v-else :src="images.img001Dark" alt="Скриншот" />
            </p>

            <p>
              3. Нажимаем на аппарат
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img003"
                alt="Скриншот"
                style="width: 50%"
              />
              <img
                v-else
                :src="images.img003Dark"
                alt="Скриншот"
                style="width: 50%"
              />
            </p>

            <p>
              4. Нажимаем на кнопку «Скачать QrCode» и файл с наклейкой
              скачивается нам на компьютер.
              <img
                v-if="this.theme === 'light-theme'"
                :src="images.img057"
                alt="Скриншот"
              />
              <img v-else :src="images.img057Dark" alt="Скриншот" />
            </p>
          </div>
        </question-block>

        <question-block title="Как удалить пользователя">
          <div>
            Как бы этого ни хотелось, но пока никак.
          </div>
        </question-block>

        <question-block title="Договор SLA">
          <div>
            В разработке
          </div>
        </question-block>

        <div class="mt-4">
          Не нашли ответ на свой вопрос?
          <!--          <span class="fake-link">Задайте его!</span>-->
          <router-link to="/manager/chat">Задайте его!</router-link>

          <b-form
            v-if="isShowQuestionForm"
            class="mt-2 feedback-question"
            @submit.prevent="submitHandler"
          >
            <b-form-input
              v-model="question"
              placeholder="Введите свой вопрос"
            />

            <button class="btn btn-second add-item__btn mt-2" type="submit">
              Отправить
            </button>
          </b-form>
        </div>
      </vuescroll>
    </div>
  </TepmplateBlock>
</template>

<script>
import TepmplateBlock from "../../components/TepmplateBlock";
import vuescroll from "vuescroll";
import QuestionBlock from "../../components/QuestionBlock";
import { theme } from "@/main";

export default {
  name: "Instruction",
  components: { QuestionBlock, TepmplateBlock, vuescroll },
  data() {
    return {
      question: "",
      isShowQuestionForm: false,
      theme: theme,
      images: {
        img001: require("../../resource/img/instruction/image001.png"),
        img001Dark: require("../../resource/img/instruction/image001Dark.png"),
        img003: require("../../resource/img/instruction/image003.png"),
        img003Dark: require("../../resource/img/instruction/image003Dark.png"),
        img005: require("../../resource/img/instruction/image005.png"),
        img005Dark: require("../../resource/img/instruction/image005Dark.png"),
        img007: require("../../resource/img/instruction/image007.png"),
        img007Dark: require("../../resource/img/instruction/image007Dark.png"),
        img009: require("../../resource/img/instruction/image009.png"),
        img009Dark: require("../../resource/img/instruction/image009Dark.png"),
        img011: require("../../resource/img/instruction/image011.png"),
        img011Dark: require("../../resource/img/instruction/image011Dark.png"),
        img013: require("../../resource/img/instruction/image013.png"),
        img013Dark: require("../../resource/img/instruction/image013Dark.png"),
        img017: require("../../resource/img/instruction/image017.png"),
        img017Dark: require("../../resource/img/instruction/image017Dark.png"),
        img019: require("../../resource/img/instruction/image019.png"),
        img019Dark: require("../../resource/img/instruction/image019Dark.png"),
        img021: require("../../resource/img/instruction/image021.png"),
        img021Dark: require("../../resource/img/instruction/image021Dark.png"),
        img023: require("../../resource/img/instruction/image023.png"),
        img023Dark: require("../../resource/img/instruction/image023Dark.png"),
        img025: require("../../resource/img/instruction/image025.png"),
        img025Dark: require("../../resource/img/instruction/image025Dark.png"),
        img027: require("../../resource/img/instruction/image027.png"),
        img027Dark: require("../../resource/img/instruction/image027Dark.png"),
        img029: require("../../resource/img/instruction/image029.png"),
        img029Dark: require("../../resource/img/instruction/image029Dark.png"),
        img031: require("../../resource/img/instruction/image031.png"),
        img031Dark: require("../../resource/img/instruction/image031Dark.png"),
        img033: require("../../resource/img/instruction/image033.png"),
        img033Dark: require("../../resource/img/instruction/image033Dark.png"),
        img035: require("../../resource/img/instruction/image035.png"),
        img035Dark: require("../../resource/img/instruction/image035Dark.png"),
        img037: require("../../resource/img/instruction/image037.png"),
        img037Dark: require("../../resource/img/instruction/image037Dark.png"),
        img039: require("../../resource/img/instruction/image039.png"),
        img039Dark: require("../../resource/img/instruction/image039Dark.png"),
        img041: require("../../resource/img/instruction/image041.png"),
        img041Dark: require("../../resource/img/instruction/image041Dark.png"),
        img043: require("../../resource/img/instruction/image043.png"),
        img043Dark: require("../../resource/img/instruction/image043Dark.png"),
        img047: require("../../resource/img/instruction/image047.png"),
        img047Dark: require("../../resource/img/instruction/image047Dark.png"),
        img049: require("../../resource/img/instruction/image049.png"),
        img049Dark: require("../../resource/img/instruction/image049Dark.png"),
        img051: require("../../resource/img/instruction/image051.png"),
        img051Dark: require("../../resource/img/instruction/image051Dark.png"),
        img053: require("../../resource/img/instruction/image053.png"),
        img053Dark: require("../../resource/img/instruction/image053Dark.png"),
        img055: require("../../resource/img/instruction/image055.png"),
        img055Dark: require("../../resource/img/instruction/image055Dark.png"),
        img057: require("../../resource/img/instruction/image057.png"),
        img057Dark: require("../../resource/img/instruction/image057Dark.png")
      }
    };
  },
  methods: {
    submitHandler() {
      console.log(123);
    }
  }
};
</script>

<style scoped>
.feedback-question {
  border: 1px tomato solid;
  padding: 10px 5px;
}
</style>
